import axios from 'axios';
import { toast } from 'react-toastify';
import { API_URL, GATSBY_SIGNATURE } from './config';
import { handleError } from './error';
import { GTM } from '@/utils/google-tag-manager';
import { GTMEventType } from '@/types/google-tag-manager.interface';

export const saveSubscriber = async (contactData: SubscriberData) => {
  try {
    await axios.post(`${API_URL}/subscribers`, {
      ...contactData,
      signature: GATSBY_SIGNATURE,
    });

    GTM.sendEvent({
      event: GTMEventType.SuccessSubscriptionRequest,
    });

    toast.success(
      'We have received your subscription, please verify your email!'
    );
  } catch (e) {
    GTM.sendEvent({
      event: GTMEventType.FailedSubscriptionRequest,
    });

    handleError(e);
  }
};

export type SubscriberData = {
  email: string;
};
