export enum GTMEventType {
  // contacts
  RequestContact = 'request_contact',
  SuccessContactRequest = 'success_contact_request',
  FailedContactRequest = 'failed_contact_request',

  // subscriptions
  RequestSubscription = 'request_subscription',
  SuccessSubscriptionRequest = 'success_subscription_request',
  FailedSubscriptionRequest = 'failed_subscription_request',
}

export type GTMPayload = {
  // contacts
  [GTMEventType.RequestContact]: {};
  [GTMEventType.SuccessContactRequest]: {};
  [GTMEventType.FailedContactRequest]: {};

  // subscriptions
  [GTMEventType.RequestSubscription]: {};
  [GTMEventType.SuccessSubscriptionRequest]: {};
  [GTMEventType.FailedSubscriptionRequest]: {};
};

export type GTMEvent = EventMap<GTMPayload>;

type EventMap<Payload> = {
  [Prop in keyof Payload]: { event: Prop } & Payload[Prop];
}[keyof Payload];
