import { GTMEvent } from '@/types/google-tag-manager.interface'

declare global {
  interface Window {
    dataLayer: GTMEvent[]
  }
}

export class GTM {
  static sendEvent(args?: GTMEvent) {
    if (!args || !window) return

    window.dataLayer = window.dataLayer || []

    window.dataLayer.push(args)
  }
}
