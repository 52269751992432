import React, { useMemo } from 'react';
import { FormProvider } from '@/hook-form';
import RHInput from '@/hook-form/RHInput';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { saveSubscriber, SubscriberData } from '@/npc-api/subscriber';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { GTM } from '@/utils/google-tag-manager';
import { GTMEventType } from '@/types/google-tag-manager.interface';

const Subscription = () => {
  const ContactSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email(),
  });

  const defaultValues = useMemo(
    () => ({
      email: '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const methods = useForm<SubscriberData>({
    resolver: yupResolver(ContactSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: SubscriberData) => {
    GTM.sendEvent({
      event: GTMEventType.RequestSubscription,
    });

    await saveSubscriber(data).then((e) => reset({ email: '' }));
  };
  return (
    <section className="sec-d7">
      <div className="container-fluid">
        <div className="row row-cols-1 row-cols-md-3 justify-content-center align-items-lg-center">
          <div className="col newsletter-content">
            <div className="cm-ft">
              <div className="d-flex align-items-center">
                <i className="fas fa-paper-plane"></i>{' '}
                <h5 className="text-subscribe">
                  {' '}
                  Subscribe to our newsletter{' '}
                </h5>
              </div>
              <FormProvider
                name="ftmn"
                methods={methods}
                onSubmit={handleSubmit(onSubmit)}
                className="form-group d-flex align-items-start"
              >
                <RHInput
                  name="email"
                  type="text"
                  className="form-control"
                  placeholder="Enter your email"
                />
                <button
                  disabled={isSubmitting}
                  type="submit"
                  className="btn sub m-0"
                >
                  {isSubmitting ? '...Loading' : 'Subscribe'}
                </button>
              </FormProvider>
            </div>
          </div>
          <div className="col call-us-content cf">
            <div className="cm-ft">
              <div className="d-flex align-items-center">
                <i className="bi bi-envelope"></i>{' '}
                <h5 className="text-subscribe"> Contact & Appointment </h5>
              </div>
              <p>
                <OutboundLink
                  className="text-subscribe"
                  style={{
                    textDecoration: 'none',
                    color: '#fff',
                  }}
                  href={'mailto:' + 'startprofessionalcare@yahoo.com'}
                >
                  {' '}
                  startprofessionalcare@yahoo.com{' '}
                </OutboundLink>
              </p>
            </div>
          </div>
          <div className="col call-us-content">
            <div className="cm-ft telsub">
              <div className="d-flex align-items-center">
                <i className="bi bi-telephone-forward-fill"></i>{' '}
                <h5 className="text-subscribe"> Call us </h5>
              </div>
              <p>
                {' '}
                <OutboundLink
                  className="text-subscribe"
                  style={{
                    textDecoration: 'none',
                    color: '#fff',
                  }}
                  href={'tel:' + '+13053968973'}
                >
                  {' '}
                  +1 (305) 396-8973{' '}
                </OutboundLink>{' '}
              </p>
            </div>
            <img src="images/er.jpg" alt="picm" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Subscription;
